import React, { Fragment } from 'react'
import Hamburger from "hamburger-react"
import Logo from '../assets/logo.svg'
import { NavLink } from 'react-router-dom'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'

function Navbar() {

    const [isOpen, setIsOpen] = React.useState(false);
    const [activeSignup, setActiveSignup] = React.useState(false);
    const [activeLogin, setActiveLogin] = React.useState(false);

    return (
        <nav className={'flex sticky max-h-screen sm:h-auto top-0 bottom-0 overflow-y-scroll xl:overflow-visible z-50 space-x-10 px-10 lg:px-20 items-center justify-between flex-wrap xl:h-32 py-6 xl:py-0 shadow-navbar bg-white'}>
            <a href="/">
                <img src={Logo} alt="Logo" className='h-16' />
            </a>
            <div className="block cursor-pointer xl:hidden">
                <Hamburger color="#3B7DDD" onToggle={() => {setIsOpen(!isOpen);setActiveLogin(false);setActiveSignup(false);}} />
            </div>
            {isOpen ?
                <div className={'space-y-5 mb-5 text-inactive mt-8 font-semibold transform duration-300 text-lg text-center items-center justify-center flex flex-col w-full relative' + (isOpen ? "h-96" : 'h-40')}>
                    <NavLink to="/">Home</NavLink>
                    <NavLink to="/candidates">For Candidates</NavLink>
                    <NavLink to="/companies">For Companies</NavLink>
                    <NavLink to="/blogs">Blogs</NavLink>
                    <div className='flex items-center cursor-pointer space-x-2 text-white font-extrabold justify-center bg-primary w-32 py-3 rounded-xl' onClick={() => {setActiveSignup(!activeSignup);setActiveLogin(false);}}>
                        <p>Sign Up </p>
                        {activeSignup ? <BsChevronUp /> : <BsChevronDown />}
                    </div>
                    {activeSignup &&
                        <div className='xl:h-28 text-lg py-4 xl:p-6 bg-primary xl:text-secondary w-full text-white font-bold xl:absolute xl:right-60 xl:w-fit xl:top-32 text-center'>
                            <a href={global.config.candidatesignup}>Candidate Sign Up</a>
                            <hr className='my-3' />
                            <a href={global.config.companysignup}>Company Sign Up</a>
                        </div>
                    }
                    <div className='border text-primary space-x-2 font-extrabold flex justify-center items-center border-primary w-32 py-3 rounded-xl' onClick={() => {setActiveLogin(!activeLogin);setActiveSignup(false);}}>
                        <p>Log in </p>
                        {activeLogin ? <BsChevronUp /> : <BsChevronDown />}
                    </div>
                    {activeLogin &&
                        <div className='xl:h-28 text-lg py-4 xl:p-6 bg-white border border-primary text-secondary w-full font-bold xl:absolute xl:right-20 xl:w-fit xl:top-32 text-center'>
                            <a href={global.config.candidatelogin}>Candidate Log in</a>
                            <hr className='my-3 bg-primary' />
                            <a href={global.config.companylogin}>Company Log in</a>
                        </div>
                    }
                    {/* <a href={global.config.candidatelogin} className='border text-primary font-extrabold flex justify-center items-center border-primary w-32 py-3 rounded-xl'>Log in</a>
                    <a href={global.config.signup} className='text-white font-extrabold flex justify-center items-center bg-primary w-32 py-3 rounded-xl transform hover:border-primary border hover:scale-110 duration-300 hover:bg-transparent hover:text-primary'>Sign Up</a> */}
                </div>
                :
                <Fragment>
                    <div className='space-x-10 hidden text-inactive font-semibold nav-links text-lg text-center xl:flex justify-between relative'>
                        <NavLink className='py-12' exact="true" to="/">Home</NavLink>
                        <NavLink className='py-12' exact="true" to="/candidates">For Candidates</NavLink>
                        <NavLink className='py-12' exact="true" to="/companies">For Companies</NavLink>
                        <NavLink className='py-12' exact="true" to="/blogs">Blog</NavLink>
                    </div>
                    <div className='hidden xl:flex space-x-10 items-center'>
                        <div className='flex items-center cursor-pointer space-x-2 text-white font-extrabold justify-center bg-primary w-32 py-3 rounded-xl' onClick={() => {setActiveSignup(!activeSignup);setActiveLogin(false);}}>
                            <p>Sign Up </p>
                            {activeSignup ? <BsChevronUp /> : <BsChevronDown />}
                        </div>
                        <div className='border text-primary space-x-2 font-extrabold cursor-pointer flex justify-center items-center border-primary w-32 py-3 rounded-xl' onClick={() => {setActiveLogin(!activeLogin);setActiveSignup(false);}}>
                            <p>Log in </p>
                            {activeLogin ? <BsChevronUp /> : <BsChevronDown />}
                        </div>
                        {/* <a href={global.config.candidatelogin} className='border text-primary font-extrabold flex justify-center items-center border-primary w-32 py-3 rounded-xl transform hover:scale-110 duration-300 hover:bg-primary hover:text-white'>Log in</a>
                        <a href={global.config.signup} className='text-white font-extrabold flex justify-center items-center bg-primary w-32 py-3 rounded-xl transform hover:border-primary border hover:scale-110 duration-300 hover:bg-transparent hover:text-primary'>Sign Up</a> */}
                    </div>
                    {activeSignup &&
                        <div className='h-32 text-lg p-6 bg-white text-secondary font-bold absolute right-60 w-fit top-32 text-center'>
                            <a href={global.config.candidatesignup} className='cursor-pointer mb-2'>Candidate Sign Up</a>
                            <hr className='my-3 bg-primary' />
                            <a href={global.config.companysignup} className='cursor-pointer mt-2'>Company Sign Up</a>
                        </div>
                    }
                    {activeLogin &&
                        <div className='h-32 text-lg p-6 bg-white text-secondary font-bold absolute right-20 w-fit top-32 text-center'>
                            <a href={global.config.candidatelogin} className='cursor-pointer mb-2'>Candidate Log in</a>
                            <hr className='my-3 bg-primary' />
                            <a href={global.config.companylogin} className='cursor-pointer mt-2'>Company Log in</a>
                        </div>
                    }
                </Fragment>
            }
        </nav>
    )
}

export default Navbar
