import React from 'react';
import logo from '../assets/footerlogo.svg';
import { ReactComponent as Linkedin } from '../assets/linkedin.svg'
import { ReactComponent as Instagram } from '../assets/instagram.svg'
import { ReactComponent as Facebook } from '../assets/facebook.svg'
import { HashLink } from 'react-router-hash-link'
import { NavLink } from 'react-router-dom'


function Footer() {
    return (
        <div className='mt-20 text-center lg:text-left p-10 md:p-20 bg-primary grid grid-cols-9'>
            <div className='flex flex-col justify-end col-span-9 lg:col-span-3'>
                <img src={logo} alt="Blackup" className='w-5/12 mx-auto lg:mx-0' />
                <div className='flex items-center justify-center lg:justify-start mt-6 space-x-12'>
                    <a href="https://www.facebook.com/blackup.tech" target="_blank" rel="noopener noreferrer">
                        <Facebook className='bg-white w-10 h-10 py-3 rounded-full' />
                    </a>
                    <a href="https://www.instagram.com/blackup_tech/" target="_blank" rel="noopener noreferrer">
                        <Instagram className='bg-white w-10 h-10 py-3 rounded-full' />
                    </a>
                    <a href="https://www.linkedin.com/company/blackup-technologies/" target="_blank" rel="noopener noreferrer">
                        <Linkedin className='bg-white w-10 h-10 py-3 rounded-full' />
                    </a>
                </div>
            </div>
            <div className='col-span-9 lg:col-span-2 mt-6 lg:mt-0'>
                <p className='font-bold text-2xl md:text-4xl text-white'>Home</p>
                <div className='text-white mt-3 md:mt-6 opacity-70 text-lg md:text-2xl font-bold'>
                    <HashLink to="/#blackup">
                        Why Black Up
                    </HashLink>
                    <HashLink to="/#offer">
                        <p className='mt-2 md:mt-4'>What We Offer</p>
                    </HashLink>
                    {/* <li className='mt-2 md:mt-4'>Our Offer</li> */}
                    <NavLink to="/blogs">
                        <p className='mt-2 md:mt-4'>Blog</p>
                    </NavLink>
                </div>
            </div>
            <div className='col-span-9 lg:col-span-2 mt-6 lg:mt-0'>
                <p className='font-bold text-2xl md:text-4xl text-white'>For Candidate</p>
                <div className='text-white mt-3 md:mt-6 opacity-70 text-lg md:text-2xl font-bold'>
                    <HashLink to="/candidates/#dream">
                        <p>Find your dream job</p>
                    </HashLink>
                    <HashLink to="/candidates/#profile">
                        <p className='mt-2 md:mt-4'>Create profile</p>
                    </HashLink>
                </div>
            </div>
            <div className='col-span-9 lg:col-span-2 mt-6 lg:mt-0'>
                <p className='font-bold text-2xl md:text-4xl text-white'>For Companies</p>
                <div className='text-white mt-3 md:mt-6 opacity-70 text-lg md:text-2xl font-bold'>
                    <HashLink to="/companies/#weserve">
                        <p>Who we serve</p>
                    </HashLink>
                    <HashLink to="/companies/#weare">
                        <p className='mt-2 md:mt-4'>Who we are</p>
                    </HashLink>
                    {/* <li className='mt-2 md:mt-4'>Who serve</li> */}
                </div>
            </div>

        </div>
    )
}

export default Footer
