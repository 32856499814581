import React from 'react'
import { ReactComponent as Curve } from '../assets/curve.svg';
import hero from '../assets/hero.png';
import check from '../assets/check.svg';
import users from '../assets/users.svg';
import yellowcheck from '../assets/yellowcheck.svg';
// import arrow from '../assets/arrow.svg';
import rocket from '../assets/rocket.svg';
import eye from '../assets/eye.svg';
import book from '../assets/book.svg';
import activity from '../assets/activity.svg';
import codesandbox from '../assets/codesandbox.svg';
import airplay from '../assets/airplay.svg';
import edit from '../assets/edit.svg';
import anderson from '../assets/team/anderson.svg';
import agbonkonkon from '../assets/team/agbonkonkon.svg';
import keith from '../assets/team/keith.svg';
// import rich from '../assets/team/rich.svg';
import whyblackup from '../assets/whyblackup.svg';
import statistics from '../assets/statistics.svg';

function Home() {

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const aboutDetails = [
        {
            icon: rocket,
            alt: "rocket",
            title: "Our Mission",
            description: "To use our A.I. tools and platform to increase the pool of Black professionals available to corporations all over the U.S., so they meet their BEI objectives.",
        },
        {
            icon: eye,
            alt: "eye",
            title: "Our Vision",
            description: "To become the blueprint of all other agencies and technologies that are used to find and hire more working Black professionals, thus lowering the unemployment rate of highly qualified talent to all-time lows.",
        },
        {
            icon: book,
            alt: "book",
            title: "Our Philosophy",
            description: "We keep it simple. Create a home for incredible Black talent to network and connect while offering access to these candidates for all corporations and businesses seeking to improve their workforce.",
        }
    ]

    const offers = [
        {
            icon: activity,
            description: "Data-driven insights to transform your diversity hiring process.",
        },
        {
            icon: codesandbox,
            description: "Build your workplace strength while showing your company values.",
        },
        {
            icon: airplay,
            description: "Insights into your entire hiring and search process.",
        },
        {
            icon: edit,
            description: "Track your Black Equity and Inclusion Metric and progression.",
        },
    ]

    const teammebers = [
        {
            profile: agbonkonkon,
            name: "Christabel Agbonkonkon",
            designation: "CEO/Founder",
            about: "MPA with 20+ years of building businesses, leading & managing multiple companies, including a chapter of 7-million women's organization."
        },
        {
            profile: anderson,
            name: "Dr. Greg Anderson",
            designation: "CTO/Co-founder",
            about: "Professor of Information Systems, PhD and MBA with 30+ years in computer science, technology management, information assurance, software engineering & technology transfer.",
        },
        {
            profile: keith,
            name: "Dr. Mark Keith",
            designation: "CIO/Co-founder",
            about: "Professor of Information Systems. MBA with 20+ years in business management, machine learning, data analytics & software engineering."
        },
        // {
        //     profile: rich,
        //     name: "Rich Condie",
        //     designation: "COO",
        //     about: "Four-time CEO of consumer goods manufacturing companies. MBA with experience in major companies and startups.",
        // },
        // {
        //     profile: anderson,
        //     name: "Dr. Greg Anderson",
        //     designation: "CTO/Co-founder",
        //     about: "Professor of Information Systems. MBA with 30+ years in computer science, technology management, information assurance, software engineering & technology transfer",
        // },
        // {
        //     profile: agbonkonkon,
        //     name: "Christabel Agbonkonkon",
        //     designation: "CEO/Founder",
        //     about: "MPA with 20+ years of building businesses, leading & managing multiple companies, including a chapter of 7-million women's organization"
        // },
        // {
        //     profile: keith,
        //     name: "Mark Keith",
        //     designation: "CIO/Co-founder",
        //     about: "Professor of Information Systems. MBA with 20+ years in business management, machine learning, data analytics & software engineering."
        // },
        // {
        //     profile: emiley,
        //     name: "Emiley",
        //     designation: "Head of Design",
        //     about: "Ipsum ad Lorem dolore amet ex ut ullamco in eu. Consectetur excepteur elit sint elit incididunt elit non laboris dolor exercitation adipisicing eu proident. Cupidatat amet proident sit eu voluptate sint qui reprehenderit eu dolor ullamco."
        // },
    ]
    return (
        <div>

            {/* Hero section */}

            <div className='relative px-10 bg-herobg lg:p-20 lg:pr-0 grid grid-cols-2'>
                <div className='col-span-2 lg:col-span-1'>
                    <p className='text-3xl md:w-11/12 leading-10 lg:w-full lg:text-54 lg:leading-80 mt-10 font-extrabold'>The <span className='text-primary'>#1 Resource</span> for Companies to Achieve Black Diversity!</p>
                    <Curve className='mt-6 w-72 sm:w-auto' style={{ stroke: "#495057" }} />
                    <p className='font-semibold pr-0 lg:pr-12 md:w-11/12 lg:w-10/12 mt-6 lg:mt-12 text-lg lg:text-2xl text-secondary'>BlackUp uses modern A.I. and ML technology to connect fully qualified, experienced, and professional Black candidates with open positions in leading companies.</p>
                    <a href={global.config.candidatelogin} className='p-4 w-fit flex lg:p-7 px-10 mt-10 text-center rounded-xl bg-primary font-extrabold text-xl text-white transform hover:border-primary border hover:scale-110 duration-300 hover:bg-transparent hover:text-primary'>See How it works</a>
                </div>
                <div className='col-span-2 lg:col-span-1 mt-60 md:mt-0'>
                    <img src={hero} alt="Handsome Young man" className='relative h-full lg:absolute lg:h-5/6 z-0 bottom-0 right-0' />
                    <div className='absolute top-7/12 md:top-1/2 lg:relative lg:top-0' data-aos="fade-right">
                        <div className='bg-white transform hover:scale-125 duration-300 w-32 sm:w-48 rounded-xl p-4 md:py-6 px-2'>
                            <div className='flex items-center space-x-2'>
                                <img src={check} alt="tick mark" className='bg-tick rounded-full' />
                                <p className='text-sm font-semibold text-secondary'>Get Hired</p>
                            </div>
                            <p className='text-secondary mt-2 sm:mt-4 text-xs font-semibold opacity-70'>Get matched with hundreds of applicants</p>
                        </div>
                    </div>
                    <div className='absolute top-7/12 md:top-1/2 lg:top-20 right-4 sm:right-10' data-aos="fade-right">
                        <div className='bg-white flex space-x-2 transform hover:scale-125 duration-300 p-4 px-2 w-40 md:w-60 rounded-xl'>
                            <img src={statistics} alt="Statistics" className='w-10 h-10' />
                            <p className='text-xs md:text-sm font-semibold opacity-70'>Track your Black Equity and Inclusion Metric and progression</p>
                        </div>
                    </div>
                    <div className='absolute sm:bottom-24 bottom-10 lg:left-5/12' data-aos="fade-right">
                        <div className='bg-white w-32 sm:w-80 transform hover:scale-125 duration-300 z-10 rounded-2xl sm:rounded-full p-4 md:py-6 px-4'>
                            <div className='block sm:flex items-center sm:space-x-2'>
                                <img src={users} alt="tick mark" className='bg-primary p-2 rounded-full' />
                                <p className='text-sm font-semibold text-secondary'>Connect with other Black professionals at top companies</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Why Blackup */}

            <div className='pt-12 relative lg:pt-20 px-10 lg:px-20' id="blackup">
                <p className='font-bold text-center text-4xl lg:text-6xl'>Why BlackUp ?</p>
                <div className='grid grid-cols-5 mt-0 lg:mt-16'>
                    <div className='col-span-5 lg:col-span-2'>
                        <img src={whyblackup} alt="Recommended Jobs" className='lg:-ml-20 -mt-10 sm:-mt-20 lg:mt-0' />
                        {/* <img src={arrow} alt="arrow" className='hidden lg:block absolute bottom-0 left-0 md:left-60' /> */}
                    </div>
                    <div className='col-span-5 lg:col-span-3'>
                        <p className='font-bold text-2xl lg:text-5xl'>BlackUp is a sophisticated non-biased <span className='text-primary'> A.I. & ML algorithm </span> that matches job openings to Black professional applicants. </p>
                        <div className='flex mt-16 space-x-10 md:w-10/12'>
                            <img src={yellowcheck} alt="" />
                            <p className='text-secondary font-bold text-lg lg:text-2xl'>A new tech-engineered platform that leverages A.I. & ML to make it easy for companies to find and hire Black talents, onboard and manage their experiences.</p>
                        </div>
                        <div className='flex mt-10 lg:mt-16 space-x-10 md:w-11/12'>
                            <img src={yellowcheck} alt="" />
                            <p className='text-secondary font-bold text-lg lg:text-2xl'>Most U.S. corporations have a goal to increase the number of Black professionals within their organization with no viable tools to achieve that goal. With the help of the BlackUp machine learning algorithm, companies can begin to make real progress.</p>
                        </div>
                    </div>
                </div>
            </div>

            {/* About */}

            <div className='my-10 grid grid-cols-3 gap-16 px-10 lg:px-20'>
                {aboutDetails.map((about, i) => {
                    return (
                        <div key={i} data-aos="zoom-in-up" data-aos-duration="500" data-aos-delay={i * 200} className={'col-span-3 lg:col-span-1 p-5 md:p-10 rounded-xl bg-primary text-white relative ' + (i === 1 ? "transform lg:scale-105" : "")}>
                            <img src={about.icon} alt={about.alt} className="bg-white rounded-full p-6" />
                            <p className='font-bold text-2xl md:text-3xl mt-4 md:mt-6'>{about.title}</p>
                            <p className='font-bold text-xl md:text-2xl mt-4 md:mt-6 opacity-80'>{about.description}</p>
                        </div>
                    )
                })}
            </div>

            {/* What we offer */}

            <div className='pt-20 py-12 px-10 lg:px-20 bg-offer' id="offer">

                <p className='font-bold text-center text-4xl lg:text-6xl'>What We Offer to Your Company</p>
                <p className='text-secondary lg:w-11/12 mx-auto text-center mt-10 lg:mt-20 font-bold text-2xl lg:text-4xl'>With BlackUp, your business will gain valuable access to a candidate pool of incredible talent. We are a Black-focused recruiting platform that runs entirely on data analytics and A.I. That means you get the skills needed to fulfill your diversity openings that improve your business and Black Equity and Inclusion requirements.</p>

                <div className='mt-10 lg:mt-20 grid grid-cols-2 gap-10 lg:gap-y-20'>
                    {offers.map((offer, i) => {
                        return (
                            <div key={i} className='col-span-2 lg:col-span-1 flex items-center space-x-5 md:space-x-8'>
                                <img src={offer.icon} alt={offer.icon} className="bg-offer rounded-full p-3 lg:p-6" />
                                <p className='text-secondary text-lg md:text-2xl font-bold'>{offer.description}</p>
                            </div>
                        )
                    })}
                </div>

                <p className='text-2xl lg:text-4xl font-bold lg:w-10/12 mx-auto mt-10 lg:mt-20 text-center'>Our business clients can post their open positions for our candidates to explore, and our individual candidates can post their resumes to be matched with these positions.</p>
                <p className='text-win mt-2 text-2xl lg:text-4xl text-center font-extrabold'>It is a win-win!</p>

            </div >

            {/* Our Team */}

            <div className='py-20 px-10 lg:px-20' >

                <p className='font-bold text-center text-4xl lg:text-6xl'>Founding Team</p>

                <div className='grid grid-cols-6 sm:gap-6'>
                    {teammebers.map((members, i) => {
                        return (
                            <div className='mt-10 lg:mt-20 cursor-pointer relative col-span-6 md:col-span-3 lg:col-span-2' key={i} data-aos="zoom-in-up" data-aos-duration="500" data-aos-delay={i * 200}>
                                <div className='relative transform hover:scale-110 duration-300'>
                                    <p className='absolute h-full flex items-center w-11/12 z-10 rounded-3xl bg-black bg-opacity-70 p-4 ml-4 text-center text-white transform opacity-0 hover:opacity-100 duration-300'>{members.about}</p>
                                    <img src={members.profile} alt={members.name} className="mx-auto w-11/12" />
                                </div>
                                <p className='text-primary font-bold text-center text-2xl mt-6'>{members.name}</p>
                                <p className='text-secondary font-bold text-center text-xl mt-2'>{members.designation}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Home
