import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Blog from './components/Blog';
import Blogs from './components/Blogs';
import Candidates from './components/Candidates';
import Companies from './components/Companies';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import AOS from 'aos';
import 'aos/dist/aos.css';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  React.useEffect(() => {
    AOS.init({
      duration: "1500"
    });
  }, [])
  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/companies" element={<Companies />} />
          <Route path="/candidates" element={<Candidates />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<Blog />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
