import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div
        className='privacy-policy'
         style={{
            fontSize: '18px',
            padding: '2% 4%',
            backgroundColor: 'white'
        }}>
            <h1 style={{
                textAlign: 'start', color: '#000',
                marginBottom: '0%', fontWeight: '600', fontSize: '38px'
            }}>
                Privacy Policy
            </h1>
            <hr style={{ margin: '24px 0' }} />
            <p>
                This Privacy Policy will refer to and apply to {' '}
                <strong> BlackUp Technologies Inc LLC </strong>
                <strong>,</strong> and this policy is used to inform visitors or users
                regarding our policies with the collection, use, and disclosure of
                Personal Information if anyone decided to access and use our Service. If
                you choose to access and use our service, then you hereby agree to the
                collection and use of information to this policy. The Personal
                Information that we collect is used for providing and improving the
                service. However, we will not use or share your information with anyone
                except as described in this Privacy Policy as&nbsp;we are committed to
                the protection of your privacy while you use our online platform,
                website, or mobile application. Moreover, we do not sell your personal
                or secret information to any third party or unauthorized person for
                personal benefit or illegal activities, but if you do not understand the
                Privacy Policy or do not agree to it, then you agree to immediately
                cease your use of our online platform, website, or mobile application.
            </p>

            <p>
                <strong>1) Definitions:</strong>
            </p>
            <p>
                We, Us, Our, Ours, these terms will be referred to as the{' '}
                <strong>&ldquo;</strong>
                <strong>BlackUp Technologies Inc&nbsp;</strong>
                <strong>LLC&rdquo;,&nbsp;</strong>while You, Your, Yours will be
                referred to as the <strong>&ldquo;User&rdquo;.</strong>
            </p>

            <p>
                <strong>2) Description of Services:</strong>
            </p>

            <p>
                We are a team of dedicated engineers and working professionals who want
                to make it easier for businesses and applicants to connect. Our goal is
                to help companies around the U.S. reach higher levels of diversity and
                inclusion by hiring experienced Black professionals that can quickly
                onboard and transition into the current company culture. Furthermore, we
                are a recruiting service and website platform designed to match Black
                professionals with open positions, and we create a home for incredible
                Black talent to network and connect while offering access to these
                candidates for all corporations and businesses seeking to improve their
                workforce.
            </p>

            <p>
                <strong>3) Account Registration:</strong>
            </p>
            <p>
                You need to provide data including your name, email address, or mobile
                number, and a password to create your account with us, by using our
                platform to get fruitful results. You hereby agree and accept you
                don&rsquo;t have the right to provide any fake details/data to misuse
                our platform in any case.
            </p>

            <p>
                <strong>4) Collection of Personal Data:</strong>
            </p>
            <p>
                For a better experience, while using our service, we may require you to
                provide us with certain personally identifiable information, including
                but not limited to your name, phone number, and postal address. We
                collect your personal data or information when you interact with us by
                accessing and using our services or carrying out other activities in
                connection with the services.&nbsp;We primarily use your Personal Data
                to help us provide a better experience for you on our website and to
                provide you the services or information you may have requested, such as
                the use of our website.&nbsp;However, the information or data that we
                collect will be used to contact or identify you.&nbsp;
            </p>

            <p>
                <strong>5) Log Data:</strong>
            </p>
            <p>
                We want to inform you that whenever you visit our service, we collect
                information that your browser sends to us which is called Log Data. This
                Log Data may include information such as your computer&apos;s Internet
                Protocol (&ldquo;IP&rdquo;) address, browser version, pages of our
                Service that you visit, the time and date of your visit, the time spent
                on those pages, and other statistics.
            </p>

            <p>
                <strong>6) Cookies:</strong>
            </p>
            <p>
                Cookies are files with a small amount of data that is commonly used as
                an anonymous unique identifier. These are sent to your browser from the
                website that you visit and are stored on your computer&apos;s hard
                drive. Our website uses these &ldquo;cookies&rdquo; to collect
                information and to improve our Service. You have the option to either
                accept or refuse these cookies and know when a cookie is being sent to
                your computer. If you choose to refuse our cookies, you may not be able
                to use some portions of our service.
            </p>

            <p>
                <strong>7) Data Security:</strong>
            </p>
            <p>
                We always adopt appropriate security measures to protect your personal
                data or information from authorized access and illegal activities. You
                hereby accept and agree that where you have created a password to access
                our services, then you are responsible to keep this password secret and
                confidential and you must not disclose it to any third party and
                therefore we will not be responsible for any unauthorized use of your
                personal information or data in any case. We value your trust in
                providing us with your Personal Information, thus we are striving to use
                commercially acceptable means of protecting it. But remember that no
                method of transmission over the internet, or method of electronic
                storage is 100% secure and reliable, and we cannot guarantee its
                absolute security.
            </p>

            <p>
                <strong>8) Disclosure of Personal Data:</strong>
            </p>
            <p>
                Although our policy is to maintain the privacy of your Personal Data as
                described herein, we may disclose your Personal Data if we believe that
                it is reasonable to do so in certain cases, at our sole and exclusive
                discretion. Such cases may include, but are not limited to:
            </p>
            <p>a) To satisfy any local, state, or Federal laws or regulations;</p>
            <p>
                b) To respond to requests, such discovery, criminal, civil, or
                administrative process, court orders, or writs from law enforcement or
                other governmental or legal bodies;
            </p>
            <p>
                c) To bring legal action against a user who has violated the law or
                violated any provision or clauses of our platform;
            </p>
            <p>d) As may be necessary for the operation of our website;</p>
            <p>
                e) To generally cooperate with any lawful investigation about our users;
            </p>
            <p>
                f) If we suspect any fraudulent activity on our website or if we have
                noticed any activity which may violate our terms or other applicable
                rules.
            </p>
            <p>
                However, we do not allow any third-party service providers to use your
                personal data for their personal benefit or illegal activities, and we
                only permit them to process your personal data for specified purposes
                under our instructions.
            </p>

            <p>
                <strong>9) Deletion of Account and Personal Data:</strong>
            </p>
            <p>
                You may delete your account and personal data stored with us at any time
                and therefore once your account is deleted, you will not be able to
                access all services.
            </p>

            <p>
                <strong>10) Links to Other Sites:</strong>
            </p>

            <p>
                Our Service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. Note that these
                external sites are not operated by us. Therefore, we strongly advise you
                to review the Privacy Policy of these websites. We have no control over
                and assume no responsibility for the content, privacy policies, or
                practices of any third-party sites or services. We do not address anyone
                under the age of 18. We do not knowingly collect personally identifiable
                information from children under 18. In the case we discover that a child
                under 18 has provided us with personal information, we immediately
                delete this from our servers. If you are a parent or guardian and you
                are aware that your child has provided us with personal information,
                please contact us so that we will be able to do the necessary actions.
            </p>

            <p>
                <strong>11) Changes to Privacy Policy:</strong>
            </p>
            <p>
                We reserve the right, power, and authority to change or modify our
                privacy policy and we may update our privacy policy from time to time
                without intimating you. Thus, we advise you to review this page
                periodically for any changes. We will notify you of any changes by
                posting the new Privacy Policy on this page. These changes are effective
                immediately after they are posted on this page.
            </p>

            <p>
                <strong>12) Legal Binding:</strong>
            </p>
            <p>
                You hereby agree that all the provisions of this privacy policy shall be
                legally binding upon you and it is enforceable in the Court of
                Law.&nbsp;Furthermore,{' '}
                <a href="https://www.lawinsider.com/clause/legally-binding?cursor=ClgSUmoVc35sYXdpbnNpZGVyY29udHJhY3RzcjQLEhZDbGF1c2VTbmlwcGV0R3JvdXBfdjMwIhhsZWdhbGx5LWJpbmRpbmcjMDAwMDAwMGEMogECZW4YACAA">
                    you hereby understand that all the provisions of this
                </a>{' '}
                privacy policy&nbsp;are legal, valid, and binding on you, and are in
                full force representing legitimate rights.
            </p>

            <p>
                <strong>13) Governing Law &amp; Jurisdiction:</strong>
            </p>
            <p>
                It is intended that this privacy policy will be conducted and executed
                fully under the laws of the United States, and it will be interpreted by
                the laws of the United States.
            </p>

            <p>
                <strong>14) Contact Us:</strong>
            </p>
            <p>
                If you have any questions about our Privacy Policy, do not hesitate to
                contact us.
            </p>
        </div>
    )
}

export default PrivacyPolicy