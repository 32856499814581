import React from 'react'
import yellowcheck from '../assets/yellowcheck.svg';
import Googlebuilding from '../assets/googlebuilding.svg';
import { ReactComponent as Barchart } from '../assets/barchart.svg';
import { ReactComponent as GoogleLogo } from '../assets/googlelogo.svg';
import professional from '../assets/professional.jpg';
import laptop from '../assets/laptop.svg';

function Companies() {

    // React.useEffect(()=>{
    //     window.scrollTo(0,0);
    // },[])

    return (
        <div>

            {/* Company section  */}

            <div className='relative px-10 lg:px-20 py-10 lg:py-28 grid gap-8 items-center grid-cols-2'>

                <div className='col-span-2 lg:col-span-1'>
                    <div className='relative mt-12 lg:mt-4'>
                        <div className='flex items-center p-4 z-20 space-x-5 absolute -top-10 right-10 bg-white rounded-full'>
                            <GoogleLogo />
                            <div>
                                <p className='font-semibold'>Product Designer</p>
                                <p className='text-xs font-medium text-secondary'>Toronto, Canada</p>
                            </div>
                            <p className='text-6xl text-secondary -mt-10'>...</p>
                        </div>
                        <img src={Googlebuilding} alt="Business place of Google" className='mx-auto' data-aos="fade-up" />
                        <Barchart className='absolute z-20 right-1/5 -bottom-10 h-32 w-32 p-6 bg-white rounded-full' />
                    </div>
                </div>

                <div className='col-span-2 lg:col-span-1'>

                    <p className='text-44 text-center lg:text-left font-bold lg:w-10/12'>Your Leading Diversity <span className='text-primary'>Hiring Resource!</span></p>
                    <p className='text-2xl lg:text-3xl mt-10 font-semibold text-secondary'>For Companies</p>
                    <div className='border-4 border-t-primary w-20 mt-4' />

                    <div className='flex mt-8 lg:mt-16 lg:space-x-10 space-x-4 w-full lg:w-10/12'>
                        <img src={yellowcheck} alt="Check mark" className='h-full mt-3' />
                        <p className='text-secondary font-bold text-lg lg:text-2xl'>Our business partners hoping to expand their growth potential and meet BEI goals can post open positions to our website.</p>
                    </div>
                    <div className='flex mt-8 lg:mt-16 lg:space-x-10 space-x-4 w-full lg:w-10/12'>
                        <img src={yellowcheck} alt="Check mark" className='h-full mt-3' />
                        <p className='text-secondary font-bold text-lg lg:text-2xl'>Our bespoke AI/ML algorithms will match your positions with the excellent talent pool of black candidates available.</p>
                    </div>
                    <div className='flex mt-8 lg:mt-16 lg:space-x-10 space-x-4 w-full lg:w-10/12'>
                        <img src={yellowcheck} alt="Check mark" className='h-full mt-3' />
                        <p className='text-secondary font-bold text-lg lg:text-2xl'>You'll be able to retain, attract, and select from highly qualified and experienced candidates all on one platform.</p>
                    </div>
                    <div className='flex mt-8 lg:mt-16 lg:space-x-10 space-x-4 w-full lg:w-10/12'>
                        <img src={yellowcheck} alt="Check mark" className='h-full mt-3' />
                        <p className='text-secondary font-bold text-lg lg:text-2xl'>Once you have selected your top potential hires, you can schedule interviews and begin to measure your black diversity metrics and progress.</p>
                    </div>
                    <a href={global.config.companylogin} target="_blank" rel="noreferrer noopener" className='py-4 lg:py-7 flex w-fit px-16 ml-6 mt-10 text-center rounded-xl bg-primary font-bold text-2xl text-white hover:bg-transparent transform duration-300 hover:border-primary border hover:scale-110 hover:text-primary'>Start Now</a>

                </div>

            </div>

            {/* Who we Serve */}

            <div className='pt-12 lg:pt-20 px-20'id="weserve">

                <p className='font-bold text-4xl lg:text-6xl text-center'>Who we Serve</p>

                <div className='flex flex-wrap md:flex-nowrap sm:space-x-4 lg:space-x-16 md:justify-around items-center mt-6 md:mt-24'>
                    <div className='flex items-center space-x-2 lg:space-x-8'>
                        <img src={yellowcheck} alt="Check mark" />
                        <p className='text-secondary font-bold text-xl lg:text-3xl'>Recruiter</p>
                    </div>
                    <div className='flex items-center mt-2 md:mt-0 space-x-2 lg:space-x-8'>
                        <img src={yellowcheck} alt="Check mark" />
                        <p className='text-secondary font-bold text-xl lg:text-3xl'>Talent Head</p>
                    </div>
                    <div className='flex items-center mt-2 md:mt-0 space-x-2 lg:space-x-8'>
                        <img src={yellowcheck} alt="Check mark" />
                        <p className='text-secondary font-bold text-xl lg:text-3xl'>CDOs/CEOs</p>
                    </div>
                    <div className='flex items-center mt-2 md:mt-0 space-x-2 lg:space-x-8'>
                        <img src={yellowcheck} alt="Check mark" />
                        <p className='text-secondary font-bold text-xl lg:text-3xl'>Diversity leaders</p>
                    </div>
                </div>

                <div className='flex flex-wrap md:flex-nowrap sm:space-x-4 lg:space-x-16 items-center lg:justify-center md:mt-20'>
                    <div className='flex items-center mt-2 md:mt-0 space-x-2 lg:space-x-8'>
                        <img src={yellowcheck} alt="Check mark" />
                        <p className='text-secondary font-bold text-xl lg:text-3xl'>Black professionals</p>
                    </div>
                    <div className='flex items-center mt-2 md:mt-0 space-x-2 lg:space-x-8'>
                        <img src={yellowcheck} alt="Check mark" />
                        <p className='text-secondary font-bold text-xl lg:text-3xl'>Corporation/SMEs</p>
                    </div>
                    <div className='flex items-center mt-2 md:mt-0 space-x-2 lg:space-x-8'>
                        <img src={yellowcheck} alt="Check mark" />
                        <p className='text-secondary font-bold text-xl lg:text-3xl'>Individual Candidates</p>
                    </div>
                </div>

            </div>

            <div className='grid grid-cols-2 bg-primary mt-20 gap-4 md:gap-10 p-12 md:p-20'>
                <div className='col-span-1 lg:px-28'>
                    <p className='text-2xl md:text-4xl md:leading-80 lg:text-54 text-white text-center font-extrabold'> <span className='opacity-70'>Get Hire from </span> 86K+ <span className='opacity-70'>Companies</span></p>
                </div>
                <div className='col-span-1 lg:px-20'>
                    <p className='text-2xl md:text-4xl md:leading-80 lg:text-54 text-white text-center font-extrabold'><span className='opacity-70'>Hire from </span> 9  Million+ <span className='opacity-70'>Black professional</span></p>
                </div>
            </div>

            {/* Who we are */}

            <div className='mt-20 lg:mt-40 px-10 lg:px-20' id="weare">

                <p className='font-bold text-3xl md:text-6xl text-center'>Who we are</p>

                <div className='relative py-10 lg:py-28 grid gap-10 grid-cols-3'>

                    <div className='col-span-3 lg:col-span-1'>
                        <div className='relative lg:mt-20' data-aos="fade-up">
                            <img src={professional} alt="" className='md:ml-0 w-3/4 md:w-2/3 lg:w-10/12 lg:-ml-12 rounded-3xl' />
                            <img src={laptop} alt="" className='absolute z-20 -bottom-64 right-0 md:right-20 lg:right-0' />
                        </div>
                    </div>

                    <div className='col-span-3 lg:col-span-2 mt-80 lg:mt-0'>

                        <p className='text-3xl lg:text-44 md:leading-60 font-bold'>We are a team of dedicated <span className='text-primary'>engineers and working professionals</span> who want to make it easier for businesses and applicants to connect.</p>

                        <div className='flex mt-8 lg:mt-16 lg:space-x-8 space-x-4 w-full lg:w-10/12'>
                            <img src={yellowcheck} alt="Check mark" className='h-full mt-3' />
                            <p className='text-secondary font-bold text-lg lg:text-2xl'>Our goal is to help companies around the U.S. reach higher levels of diversity and inclusion by hiring experienced Black professionals that can quickly onboard and transition into the current company culture.</p>
                        </div>
                        <div className='flex mt-8 lg:mt-16 lg:space-x-8 space-x-4 w-full lg:w-10/12'>
                            <img src={yellowcheck} alt="Check mark" className='h-full mt-3' />
                            <p className='text-secondary font-bold text-lg lg:text-2xl'>Most U.S. corporations have a Chief diversity officer (CDO) or follow diversity data analytics to improve the makeup of their corporate boards, staffing, and team member population. The only problem is that they don’t know where to find this highly qualified talent pool.</p>
                        </div>
                        <div className='flex mt-8 lg:mt-16 lg:space-x-8 space-x-4 w-full lg:w-10/12'>
                            <img src={yellowcheck} alt="Check mark" className='h-full mt-3' />
                            <p className='text-secondary font-bold text-lg lg:text-2xl'>That is where we step in. BlackUp uses non-biased advanced data-driven algorithms to match these new openings with working professionals from diverse backgrounds.</p>
                        </div>
                        <a href={global.config.companylogin} target="_blank" rel="noreferrer noopener" className='w-fit flex py-4 lg:py-7 px-16 ml-6 mt-16 text-center rounded-xl bg-primary font-bold text-2xl text-white hover:bg-transparent transform duration-300 hover:border-primary border hover:scale-110 hover:text-primary'>Get Started</a>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default Companies
