import React from 'react'
import yellowcheck from '../assets/yellowcheck.svg';
import candidate from '../assets/candidate.svg';
import arrow from '../assets/arrow.svg';
// import Ola from '../assets/ola.svg';
import GirlCandidate from '../assets/girlcandidate.png';
import { ReactComponent as Curve } from '../assets/curve.svg';
import { ReactComponent as Award } from '../assets/award.svg';
import { ReactComponent as Star } from '../assets/star.svg';

function Candidates() {

    // const jobs = [
    //     {
    //         logo: Ola,
    //         role: "Product Designer",
    //         company: "Ola",
    //         location: "Washington DC, USA",
    //         link: ""
    //     },
    //     {
    //         logo: Ola,
    //         role: "Product Designer",
    //         company: "Ola",
    //         location: "Washington DC, USA",
    //         link: ""
    //     },
    //     {
    //         logo: Ola,
    //         role: "Product Designer",
    //         company: "Ola",
    //         location: "Washington DC, USA",
    //         link: ""
    //     },
    //     {
    //         logo: Ola,
    //         role: "Product Designer",
    //         company: "Ola",
    //         location: "Washington DC, USA",
    //         link: ""
    //     },
    //     {
    //         logo: Ola,
    //         role: "Product Designer",
    //         company: "Ola",
    //         location: "Washington DC, USA",
    //         link: ""
    //     },
    //     {
    //         logo: Ola,
    //         role: "Product Designer",
    //         company: "Ola",
    //         location: "Washington DC, USA",
    //         link: ""
    //     },
    // ]

    React.useEffect(()=>{
        window.scrollTo(0,0);
    },[])
    
    return (
        <div>

            {/* Candiate section  */}

            <div className='relative px-10 lg:px-20 py-10 lg:py-28 grid grid-cols-2'>
                <div className='col-span-2 lg:col-span-1'>

                    <p className='text-3xl lg:text-4xl font-semibold text-secondary'>For Candidates</p>
                    <div className='border-4 border-t-primary w-20 mt-4' />

                    <div className='flex mt-8 lg:mt-16 lg:space-x-10 space-x-4 w-full lg:w-10/12'>
                        <img src={yellowcheck} alt="" className='h-full mt-3' />
                        <p className='text-secondary font-bold text-lg lg:text-2xl'>BlackUp  is a free service for our potential candidates seeking part-time, full-time, contract, remote, or hybrid work from reputable companies around the U.S.</p>
                    </div>
                    <div className='flex mt-8 lg:mt-16 lg:space-x-10 space-x-4 w-full lg:w-10/12'>
                        <img src={yellowcheck} alt="" className='h-full mt-3' />
                        <p className='text-secondary font-bold text-lg lg:text-2xl'>When you sign up for our service, you can upload your resume, add your skills, connect with friends, rate companies, and begin to apply for excellent open positions that can grow your career.</p>
                    </div>
                    <div className='flex mt-8 lg:mt-16 lg:space-x-10 space-x-4 w-full lg:w-10/12'>
                        <img src={yellowcheck} alt="" className='h-full mt-3' />
                        <p className='text-secondary font-bold text-lg lg:text-2xl'>We have the space for you to add images and videos that place your value as a candidate high on the list of these companies.</p>
                    </div>
                    <div className='flex mt-8 lg:mt-16 lg:space-x-10 space-x-4 w-full lg:w-10/12'>
                        <img src={yellowcheck} alt="" className='h-full mt-3' />
                        <p className='text-secondary font-bold text-lg lg:text-2xl'>Connect with other working black professionals and build a solid network to support you from your first job to board membership.</p>
                    </div>
                    <a href={global.config.candidatelogin} target="_blank" rel="noreferrer noopener" className='py-4 flex w-fit lg:py-7 px-16 ml-6 mt-10 text-center rounded-xl bg-primary font-bold text-2xl text-white hover:bg-transparent transform duration-300 hover:border-primary border hover:scale-110 hover:text-primary'>Start Now</a>

                </div>

                <div className='col-span-2 lg:col-span-1'>
                    <div className='relative mt-12 lg:mt-4'>
                        <div className='flex p-4 space-x-2 sm:space-x-5 z-20 absolute -top-4 sm:-top-2 bg-white rounded-full'>
                            <Star />
                            <Star />
                            <Star />
                            <Star />
                            <Star />
                        </div>
                        <img src={candidate} alt="Canddiate writing alongside a computer" className='w-3/4 sm:w-auto mx-auto' data-aos="fade-up" />
                        <Award className='absolute right-1/12 -bottom-10 w-20 h-20 sm:h-32 sm:w-32 p-6 bg-white rounded-full' />
                    </div>
                    <img src={arrow} alt="Arrow pointing to left" className='hidden lg:block transform -scale-x-100 -rotate-45 absolute right-80 bottom-60' />
                </div>

            </div>

            {/* Dream Job */}

            {/* <div className='bg-herobg my-20 py-20' id="dream">

                <p className='font-bold text-center text-3xl lg:text-6xl'>Find Your Dream Job</p>

                <div className='grid grid-cols-6 mt-16 gap-y-20'>
                    {jobs.map((job, i) => {
                        return (
                            <div key={i} className='col-span-6 md:col-span-3 lg:col-span-2 text-center bg-white p-6 w-80 rounded-2xl mx-auto' data-aos="fade-up">
                                <img src={job.logo} alt={job.company} className="mx-auto" />
                                <p className='text-2xl font-bold mt-4'>{job.role}</p>
                                <p className='text-xl font-semibold mt-4'>{job.company}</p>
                                <p className='text-xl text-secondary font-bold mt-7'>{job.location}</p>
                                <button className='py-3 px-16 mb-5 mt-10 text-center rounded-xl bg-primary font-extrabold text-xl text-white hover:bg-transparent transform duration-300 hover:border-primary border hover:scale-110 hover:text-primary'>Apply</button>
                            </div>
                        )
                    })}
                </div>

                <p className='text-center text-primary font-semibold text-2xl mt-20'>View all</p>

            </div> */}

            <div className='my-12 lg:my-40 w-10/12 mx-auto bg-primary rounded-xl grid sm:gap-20 grid-cols-6 relative h-auto lg:h-96' id="profile">
                <div className='col-span-3 sm:col-span-2'>
                    <img src={GirlCandidate} alt="A girl with books in her hand" className='relative mx-auto h-full sm:ml-4 sm:absolute sm:w-1/3 bottom-0' />
                </div>
                <div className='flex col-span-3 sm:col-span-4 text-white'>
                    <div className='pt-6 md:py-10 pr-2 lg:px-0'>
                        <p className='text-xl lg:text-5xl font-extrabold'>The future looks like you</p>
                        <Curve className='w-40 ml-24 hidden lg:block' style={{ stroke: "#fff" }} />
                        <p className='text-sm lg:text-xl font-semibold mt-3 lg:mt-6 w-full lg:w-3/4'>The only Black Recruiting Platform that runs on analytics and is AI focus.</p>
                        <a href={global.config.candidatelogin} target="_blank" rel="noopener noreferrer" className='py-2 flex w-fit px-2 mb-4 lg:py-5 sm:px-10 lg:mb-5 mt-6 lg:mt-10 text-center rounded-xl text-primary bg-white font-extrabold text-base md:text-xl hover:bg-transparent transform duration-300 hover:border-white border hover:scale-110 hover:text-white'>Create a free profile</a>
                    </div>
                    <div className='hidden lg:block w-1 bg-white bg-opacity-30 -ml-10 mr-10'></div>
                    <div className='hidden lg:block w-1 bg-white bg-opacity-30 mr-10'></div>
                    <div className='hidden lg:block w-1 bg-white bg-opacity-30'></div>
                </div>

            </div>
            
        </div>
    )
}

export default Candidates
