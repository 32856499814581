import React from 'react'
import sampleBlog from '../assets/sampleblog.svg';
import Author from '../assets/author.svg'
import { useNavigate } from 'react-router-dom';

function Blogs() {

    const navigate = useNavigate();

    const blogs = [
        {
            poster: sampleBlog,
            title: "The Codification of Bias: How Algorithms Affect Black Candidates Looking for Jobs",
            author: "Christabel Agbonkonkon",
            authorProfile: Author,
            date: "March 24, 2022",
        },
        {
            poster: sampleBlog,
            title: "7 Ways to Find a Job as a Black Professional:Career Advice for Black Professionals",
            author: "Christabel Agbonkonkon",
            authorProfile: Author,
            date: "March 17, 2022",
        },
        {
            poster: sampleBlog,
            title: "The Embedded Element of Slavery in American Companies",
            author: "Christabel Agbonkonkon",
            authorProfile: Author,
            date: "March 11, 2022",
        },
        {
            poster: sampleBlog,
            title: "7 Shocking Facts You Need to Know About Black Diversity and Inclusion in the Workplace",
            author: "Christabel Agbonkonkon",
            authorProfile: Author,
            date: "March 11, 2022",
        },
        {
            poster: sampleBlog,
            title: "7 Useful Tips From Experts on How To Find A Job In 30 Days",
            author: "Christabel Agbonkonkon",
            authorProfile: Author,
            date: "March 24, 2022",
        },
        {
            poster: sampleBlog,
            title: "The Path to Inclusivity is Overcoming Cultural Myopia in Companies.",
            author: "Christabel Agbonkonkon",
            authorProfile: Author,
            date: "February 15, 2023",
        }
//         {
//             poster: sampleBlog,
//             title: "How can Recruiters compete in Today's Hot Job Market",
//             author: "Sara Martinez",
//             authorProfile: Author,
//             date: "Jan 21, 2022",
//         },
//         {
//             poster: sampleBlog,
//             title: "How can Recruiters compete in Today's Hot Job Market",
//             author: "Sara Martinez",
//             authorProfile: Author,
//             date: "Jan 21, 2022",
//         },
//         {
//             poster: sampleBlog,
//             title: "How can Recruiters compete in Today's Hot Job Market",
//             author: "Sara Martinez",
//             authorProfile: Author,
//             date: "Jan 21, 2022",
//         },
    ]

    const redirect = (id)=>{
        navigate(`/blogs/${id}`);
    }

    React.useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    return (
        <div>

            <p className='font-bold text-4xl lg:text-6xl text-center mt-20'>Blog</p>
            <div className='grid grid-cols-6 md:gap-20 px-10 mt-10 lg:mt-20 lg:px-20'>
                {blogs.map((blog, i) => {
                    return (
                        <div key={i} className='bg-white col-span-6 md:col-span-3 cursor-pointer lg:col-span-2 rounded-2xl mt-10 md:mt-0' onClick={()=>redirect(i)} data-aos="zoom-in-up" data-aos-delay={i*200}>
                            <img src={blog.poster} alt="Blog poster" className='w-full' />
                            <div className='px-6'>
                                <p className='font-bold text-2xl mt-6'>{blog.title}</p>
                                <div className='flex items-center space-x-6 mt-6'>
                                    <img src={blog.authorProfile} alt="Author Profile" className='rounded-full' />
                                    <p className='text-secondary text-lg font-bold'>{blog.author}</p>
                                </div>
                                <p className='font-bold text-lg mt-4'>{blog.date}</p>
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    )
}

export default Blogs
